.p-10 {
  padding: 10px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.mlr-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mlr--5 {
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.width100 {
  width: 100% !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.w-100 {
  width: 100% !important;
}

.fs-18 {
  font-size: 18px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mt--20 {
  margin-top: -20px !important;
}

.mt--80 {
  margin-top: -80px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}
