/* You can add global styles to this file, and also import other style files */
// @TODO
// @import "../libs/scss/front-index";
@import "../../../libs/scss/common/margin-padding";

.mat-ripple-element {
  display: none !important;
}

.mat-progress-spinner circle {
  stroke: #3f51b5;
}

b {
  color: #000000;
}

.height-360 {
  max-height: 360px !important;
}

.fa.fa-phone {
  transform: rotate(90deg);
}

.tag_cloud_widget {
  background: #efefef;
  padding: 20px;
  text-align: center;
  border-left: 5px solid #1ec6b6;
  border-right: 5px solid #1ec6b6;
  border-radius: 12px;

  ul {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      border-radius: 8px;
      padding: 0;
      margin: 0;

      a {
        padding: 4px 10px !important;
      }
    }
  }
}

header {
  background: #fbf9ff;
}

.inner-section {
  margin-top: 10px;

  h1 {
    font-size: 24px;
    margin-bottom: 40px !important;
  }
}

.bg-blue {
  background: #007bff40;
}

.bg-green {
  background: #78e5bd36;
}

.cursor-pointer {
  cursor: pointer !important;
}

.red-link {
  cursor: pointer !important;
  color: #000;

  &:hover {
    color: #FF4A52;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.header-area {
  background: #78e5bd36;
  overflow: hidden;
}

.address-line {
  color: #a6a3a3;
  font-size: 14px;
  margin-bottom: 30px;

  a {
    color: #007bff;
  }
}

.section-under-implementation {
  width: 100%;
  background: #e1f5f2;
  padding: 30px;
  margin: 40px 0 0 0;
  font-size: 16px;
  text-align: justify;
  border-right: 8px solid #2d926d;
  border-left: 8px solid #2d926d;
  border-radius: 30px;
}

body {
  font-size: 16px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.login-errors {
  background: #ff0000;
  color: #fff;
  padding: 3px 10px 3px 15px;
  border-left: #930707;
  border-right: #930707;

  border-width: 3px;
  border-style: solid;
  border-radius: 7px;
  border-top: 0;
  border-bottom: 0;

  .login-error {
    font-size: 12px;
  }
}

.color-dark {
  color: #040E27
}

h3 {
  margin: 20px 0 10px 0;
}

.header_bottom_border {
  .logo {
    a span {
      white-space: nowrap;
      padding-left: 5px
    }
  }
}

.book-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d4edf0b5;
  z-index: 10;
  display: none;
  justify-content: center;

  &.popup-open {
    display: flex;
  }

  .popup-text {
    background: rgb(120, 229, 189);
    border-top: 4px solid rgb(75 182 143);
    border-bottom: 4px solid rgb(75 182 143);
    width: 300px;
    height: 150px;
    text-align: center;
    padding: 20px;
    margin-top: 500px;
    position: relative;

    div {
      margin-top: 25px;
      font-size: 18px;
    }

    .popup-close {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      margin-top: 0;
      margin-right: 6px;
    }
  }
}

.mat-mdc-raised-button {
  &.mat-accent {
    background: #4caf50 !important;
    color: #ffffff !important;
  }

  &.large {
    height: 50px !important;
    padding: 0 20px;
    font-size: 18px;
  }
}

.top-banner {
  img {
    width: 100%;
    height: auto;
  }
}

.blog_description {
  blockquote {
    border-left: 3px solid #afafaf;
    padding: 10px 10px 1px 25px;
    background: #f7f7f7;
  }
}

.tour-description-text {
  table {
    width: 100% !important;
    margin-top: 20px;

    thead {
      background: #e4faf2;
    }

    td {
      border-bottom: 1px solid #e0d9d9;
    }
  }
}

.tab-description {
  table {
    width: 100% !important;
    margin-top: 20px;

    thead {
      background: #e4faf2;
    }

    td {
      border-bottom: 1px solid #e0d9d9;
    }
  }
}

.destination_image {
  width: 400px;
  height: 200px;
  display: block;
}

.popular_destination_area .single_destination {
  max-height: 200px;
}

.tour-details {
  ul, ol {
    background: #e6e4e982;
    padding: 10px 10px 10px 30px;
    border-left: 6px solid #1fc6b6;
    font-size: 15px;

    li {
      padding: 3px 0;
    }
  }

  ul li {
    list-style-type: circle;
  }

  ol li {
    list-style-type: decimal;
  }
}
